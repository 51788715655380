import { defineMessages } from 'react-intl';

const messages = defineMessages({
    heading: {
        id: 'work.heading',
        defaultMessage: `Work and Projects 👨🏻‍💻 🚀`,
    },
    aboutPartOne: {
        id: 'work.about',
        defaultMessage: 'I work as a Software Engineer at Quickbase Inc. It is a dynamic work management platform. My role is to continually improve the platform and add new capabilities, so it is easier than ever to see, connect, and control most complex processes. In my role, I focus on building an intuitive, clean user experience. These are some of the features I have worked on:',
    }
});

export default messages;